<template lang="pug">
v-col.pa-0(style='background: #0E2C40')

  v-row(style="display: flex; align-items: center; justify-content: center;").row_pantalla
    v-col(cols=12 md=6 xs=10 lg=5 ).text-center  
      v-card(style='background-color:  #1A4A5A; height: 100% ;border-radius: 20px; padding: 50px; text-align: center; color: #fff')   
        
        h1(style="font-weight: 900") Inicia Sesión
        form(@submit.prevent="login()" style="max-width: 350px; margin: 0 auto; padding: 20px; border-radius: 5px;").st
          label(for="username") Email:
          input(v-model='email' type="email", name="username", , required, style="color: black ;width: 100%; padding: 8px; margin: 8px 0; border-radius: 10px; background-color: #D9D9D9")

          label.mt-3(for="password") Contraseña:
          input(v-model='password' type="password", name="password", , required, style="width: 100%; padding: 8px; margin: 8px 0; border-radius: 10px; background-color: #D9D9D9; color: ")

          button.mt-3(type="submit"  style="background-color: #4D7751; color: black; padding: 5px 18px; border: none; border-radius: 3px; cursor: pointer; border-radius: 20px")
            span(style="text-decoration: none; color: white; font-weight: bold")  Inciar Sesión

        span ¿No tienes cuenta?
          span(style="color: green; margin-left: 0.4em ; text-decoration: none; cursor: pointer" @click="dirigirA('/registro')") ¡Regístrate!

</template>
<style>

.row_pantalla {
  display: flex;
  justify-content: center; /* Centra los elementos hijos horizontalmente */
  align-items: center; /* Centra los elementos hijos verticalmente */
  height: 83vh; /* Ajusta la altura según la altura de la ventana gráfica (viewport) */
}

</style>

<script>
import { mapActions } from 'vuex'
export default {
  data: ()=>({
    email: '',
    password: '',
  }),
  methods: {
    ...mapActions({
      iniciarSesion: 'Usuarios/iniciarSesion',
    }),
    login(){
      this.iniciarSesion({email: this.email, password: this.password}).then(puedeDirigir=>{

        if(puedeDirigir) this.dirigirA('/inicio')
        else console.log('No puede dirigir')
      }).catch(error=>{
        console.log('Error', error)
      })
      
    },
    dirigirA(ruta){
      this.$router.push(ruta)
    },
  }
}
</script>
  