<template lang="pug">
v-col.pa-0(style='background: #0E2C40; ')
  v-row(style="display: flex; align-items: center; justify-content: center;").row_pantalla
    v-col(cols=12 md=5 xs=10 lg=5 ).text-center  
      v-card(style='background-color:  #1A4A5A; height: 100% ;border-radius: 20px; padding: 50px; text-align: center; color: #fff;height: 100%')   
          
          
        
        h1.mb-5(style="font-weight: 900") Registrarse
        form(@submit.prevent='registrar').white--text
          //- Nombre de usuario y Apellido de usuario en la misma fila
          .fila-inputs
            //- Nombre de usuario
            label(for='nombre')
            input.input(type='text' placeholder='Nombres' v-model='name' required='' style='margin-right: 1em').stilo_input

            //- Apellido de usuario
            label(for='apellido') 
            input#apellido(type='text' v-model='apellido' placeholder='Apellido' required='' style='margin-left: 0em' ).stilo_input

          //- Correo
          label(for='email')
          input#email(type='email' v-model='email' placeholder='Correo' required='' ).stilo_input

          //- Contraseña
          label(for='password')
          input#password(type='password' v-model='password' placeholder='Contraseña' required='' ).stilo_input

          //- Confirmación de la contraseña
          label(for='confirm-password')
          input#confirm-password(type='password' v-model='confirmPassword' placeholder='Confirmar Contraseña' required='' ).stilo_input
          button.mt-3(type="submit" style="background-color: #4CAF50; color: white; padding: 5px 18px; border: none; border-radius: 3px; cursor: pointer; border-radius: 20px")
            span Registrar
          .row.mt-3
            span ¿Ya tienes cuenta?
              span(style="color: green; margin-left: 0.4em ; text-decoration: none; cursor: pointer" @click="dirigirA('/login')") ¡Inicia sesión!
    
          
          

      div.alert(v-if="error.visible", class="alert-danger", role="alert") {{ error.message }}
      modal(v-if="isModalVisible" style="z-index: 1000;position: fixed; top: 0; left: 0; right: 0; margin: auto; background-color: #3B2387; color: #fff; padding: 10px; border-radius: 0px 0px 30px 30px; width: 30em" )
        h3 ¡Usuario registrado con éxito!
        p Tu usuario se ha registrado correctamente.
        button(type="button" @click="closeModal") Aceptar
</template>

<script>
import {mapActions} from 'vuex'
import VModal from 'vue-js-modal';

export default {
  data() {
    return {
      name: '',
      apellido: '',
      email: '',
      password: '',
      confirmPassword: '',
      error: {
        message: '',
        visible: false,
      },
      isModalVisible: false,
    };
  },
  methods: {
    ...mapActions({
      registrarUsuario: 'Usuarios/registrarUsuario'
    }),
    dirigirA(ruta){
      this.$router.push(ruta)
    },
    registrar() {
      this.error = '';
      if (!this.name || !this.email || !this.password || !this.confirmPassword) {
        this.error = { message: 'Todos los campos son requeridos', visible: true };
        // Ocultar la alerta después de 3 segundos (3000 milisegundos)
        setTimeout(() => this.error.visible = false, 3000);
        return;
      }
      // Verificar la longitud de la contraseña
      if (this.password.length < 8) {
        this.error = { message: 'La contraseña debe tener al menos 8 caracteres', visible: true };
        return;
      }
      // Verificar requisitos adicionales
      const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;
      if (!regex.test(this.password)) {
        this.error = { message: 'La contraseña debe incluir al menos una mayúscula, un número y un símbolo', visible: true };
        return;
      }

      // Verificar que la contraseña y la confirmación coincidan
      if (this.password !== this.confirmPassword) {
        this.error = { message: 'Las contraseñas no coinciden. Verifica la confirmación de contraseña.', visible: true };
        return;
      }
      this.registrarUsuario({
        email: this.email,
        password: this.password,
        otherUserData: {
          nombre: this.name,
          apellido: this.apellido,
        },
      }).then(puedeDirigir=>{
        if(puedeDirigir){ 
          this.dirigirA('/inicio')
          this.$store.dispatch('Ayudas/activar_snackbar', 'Usuario Registrado con Éxito');
          this.showModal();
          // Cerrar el modal automáticamente después de 1500 milisegundos (1.5 segundos)
          setTimeout(() => this.closeModal(), 1500);
        } else console.log('No puede dirigir')
     
     
     
      }).catch((error) => {
        this.error = error.message;
      });
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  directives: {
    'modal': VModal.directive,
  },
};
</script>
<style>

.stilo_input{
  color: black;
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  border-radius: 10px;
  background-color: #D9D9D9;
}
/* Estilos para el contenedor de la fila de inputs */
.fila-inputs {
  display: flex; /* Hace que los elementos hijos se alineen en una fila */
  justify-content: space-between; /* Distribuye el espacio entre los elementos hijos */
  align-items: center; /* Centra los elementos hijos verticalmente */
}


</style>