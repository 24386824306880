// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // Importa getFirestore
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDtLkwsOBnwUDmbeSLwQLjieJhlbE1z2f0",
  authDomain: "notas-conet.firebaseapp.com",
  projectId: "notas-conet",
  storageBucket: "notas-conet.appspot.com",
  messagingSenderId: "821077167543",
  appId: "1:821077167543:web:ba99ce420d39d06672ec32",
  measurementId: "G-54MBCSVB62"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app); // Esta línea es opcional, solo si estás usando Analytics

// Inicializa Firestore y la exporta fuera de la función
const db = getFirestore(app);

// Puedes seguir exportando tu función si necesitas hacer algo más en ella
export const inicializarFirebase = () => {
  const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
};

// Exporta la instancia de Firestore
export {db};



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional