
<template lang="pug">
.row
    .col(style="background-color: #0E2C40; padding:1em")
      .page-container
        .column-2
          h5 Nombre de Usuario
          p.email {{ userEmail }}
          button.btn-edit-profile(@click="abrirModalEditarPerfil")
            i.fa.fa-user(style='font-size: 2em; cursor: pointer;')

          button.btn-logout(@click="cerrarSesion") Cerrar Sesión

          hr
          button(type="button", @click="mostrarNuevaNota = !mostrarNuevaNota")
            i.fa.fa-plus
            span.space Nuevo Apunte
          br

          hr

          EditProfileModal(
            :mostrarModal="showEditProfile"
            :user="user"
            :cerrarModal="cerrarModalEdicionPerfil"
            :cargarFotoPerfil="cargarFotoPerfil"
            :guardarCambiosPerfil="guardarCambiosPerfil"
          )

          // Lista de apuntes recientes
          h6 Apuntes Recientes
          ul.apuntes-recientes
            li(v-for="apunte in apuntes", :key="apunte.id", @click="mostrarApunte(apunte)")
              | {{ apunte.titulo }} ({{ new Date(apunte.fecha).toLocaleDateString() }})
              button(@click="editarApunte(apunte)")
                i.fa.fa-edit
              button(@click="eliminarApunte(apunte.id)")
                i.fa.fa-trash-alt

    .column-10
      // Mostrar apunte seleccionado
      template(v-if="apunteSeleccionado")
        .apunte-detallado
          h3 {{ apunteSeleccionado.titulo }}
          p {{ apunteSeleccionado.contenido }}
          .footer
            span.categoria {{ apunteSeleccionado.categoria }}
            span.fecha {{ new Date(apunteSeleccionado.fecha).toLocaleDateString() }}
  
      // Crear nueva nota
      template(v-if="mostrarNuevaNota")
        .card-nueva-nota
          h3 Nueva Nota
          .form-group
            label(for="titulo") Título
            input(type="text", v-model="nuevaNota.titulo", placeholder="Ingrese el título de la nota")
  
          .form-group
            label(for="categoria") Categoría
            input(type="text", v-model="nuevaNota.categoria", placeholder="Categoria" )

          .form-group
            label(for="contenido") Contenido
            quill-editor(v-model="nuevaNota.contenido")
  
          .form-group
            label(for="fecha") Fecha
            input(type="date", v-model="nuevaNota.fecha")
  
          button(type="button", @click="guardarNuevaNota") Guardar Nota
  
      // Gestionar categorías
      //*template(v-if="mostrarCategoria")
        .categoria
          h2 Categoría
          .form-group-c
            button(@click="showAddCategoryForm") Agregar Categoría
            form(v-if="showForm", @submit.prevent="addCategory")
              .form-group-ca
                label(for="name") Nombre de la categoría:
                input(type="text", id="categoryName", v-model="newCategoryName.name", required)
              button(type="submit") Agregar
          // Listado de categorías existentes
          h3 Categorías Existentes
          ul
            li(v-for="categoria in categorias", :key="categoria.id")
              | {{ categoria.name }}
              button(@click="editarCategoria(categoria)")
                i.fa.fa-edit

              button(@click="eliminarCategoria(categoria.id)")
                i.fa.fa-trash-alt
        *//
      // Interfaz de edición de perfil
      template(v-if="showEditProfile")
        .edit-profile-container
          h2 Edición de Perfil
          .profile-picture
            span.i.fa.fa-user.profile-icon
          .profile-fields
            .field-container
              label Nombre
              input(type="text" placeholder="Nombre")
            .field-container
              label Apellido
              input(type="text" placeholder="Apellido")
            .field-container
              label Nombre de Usuario
              input(type="text" placeholder="Nombre de Usuario")
    EditModal(v-if="mostrarModalEdicion" :apunte="apunteParaEditar" @guardar="guardarEdicionApunte" @cerrar="cerrarModalEdicion")
    EditProfileModal(v-if="showEditProfile", :user="user", :cerrarModal="cerrarModalEdicionPerfil", :cargarFotoPerfil="cargarFotoPerfil", :guardarCambiosPerfil="guardarCambiosPerfil")

</template>
  
<script>
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.snow.css'; // Tema snow de Quill
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { db } from '../../FirebaseConfig';
import { collection, addDoc, getDocs, query, where, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import EditModal from "@/views/web/EditModal.vue";
import DOMPurify from 'dompurify';
import EditProfileModal from "@/views/web/EditProfileModal.vue";
export default {
  components: {
    quillEditor,
    EditModal,
    EditProfileModal
  },
  data() {
    return {
      userId: null, // ID del usuario autenticado
      userEmail: null, // Correo electrónico del usuario autenticado
      showDropdown: false,
      selectedItem: null,
      mostrarCategoria: false,
      mostrarNuevaNota: false,
      apunteSeleccionado: null, // Almacena el apunte seleccionado
      mostrarModalEdicion: false,
      apunteParaEditar: null,
      user: {},
      nuevaNota: {
        titulo: "",
        categoria: "",
        contenido: "", // Contenido para el editor Quill
        fecha: "",
      },
      newCategoryName: { name: "" }, // Definición de newCategoryName
      showForm: false,
      showEditProfile: false, // Controla la visibilidad de la interfaz de edición de perfil
      apuntes: [], // Apuntes cargados desde Firebase
      categorias: [], // Categorías cargadas desde Firebase
    };
  },
  methods: {
    // Métodos para Categorías
    showAddCategoryForm() {
      this.showForm = !this.showForm;
    },
    async addCategory() {
      try {
        await addDoc(collection(db, "categorias"), {
          name: this.newCategoryName.name
        });
        console.log("Categoria Guardada");
        this.newCategoryName.name = "";
        this.loadCategories(); // Recargar categorías
      } catch (error) {
        console.error("Error al guardar", error);
      }
    },
    async loadCategories() {
      try {
        const querySnapshot = await getDocs(collection(db, "categorias"));
        this.categorias = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error("Error al cargar categorías", error);
      }
    },
    async eliminarCategoria(categoriaId) {
      try {
        const categoriaRef = doc(db, "categorias", categoriaId);
        await deleteDoc(categoriaRef);
        console.log("Categoría eliminada con éxito");
        this.loadCategories(); // Recargar categorías
      } catch (error) {
        console.error("Error al eliminar la categoría", error);
      }
    },
    async editarCategoria(categoria) {
      try {
        const nuevoNombre = prompt('Ingrese el nuevo nombre para la categoría:', categoria.name);
        if (nuevoNombre !== null) {
          const categoriaRef = doc(db, 'categorias', categoria.id);
          await updateDoc(categoriaRef, { name: nuevoNombre });
          console.log('Categoría editada con éxito');
          this.loadCategories(); // Recargar categorías
        }
      } catch (error) {
        console.error('Error al editar la categoría:', error);
      }
    },

    // Métodos para Apuntes
    mostrarApunte(apunte) {
      this.apunteSeleccionado = apunte;
    },

    editarApunte(apunte) {
      this.apunteParaEditar = apunte;
      this.mostrarModalEdicion = true;
    },

    guardarEdicionApunte(apunteEditado) {
      console.log('Guardar edición:', apunteEditado);


      const apunteRef = doc(db, "notas", apunteEditado.id);
      updateDoc(apunteRef, {
        titulo: apunteEditado.titulo,
        contenido: apunteEditado.contenido,
        fecha: apunteEditado.fecha,
        categoria: apunteEditado.categoria,

      })
          .then(() => {
            console.log('Apunte actualizado con éxito');
          })
          .catch((error) => {
            console.error('Error al actualizar el apunte:', error);
          });
    },

    cerrarModalEdicion() {
      this.mostrarModalEdicion = false;
    },

    eliminarApunte(apunteId) {
      if (confirm("¿Estás seguro de que deseas eliminar este apunte?")) {
        const apunteRef = doc(db, "notas", apunteId);

        deleteDoc(apunteRef)
            .then(() => {
              console.log('Apunte eliminado con éxito');
              // Vuelve a cargar la lista de apuntes después de eliminar
              this.cargarNotas();
            })
            .catch((error) => {
              console.error('Error al eliminar el apunte:', error);
            });
      }
    },


    async guardarNuevaNota() {
      if (!this.userId) {
        console.error("Usuario no autenticado");
        return;
      }
      try {
        await addDoc(collection(db, "notas"), {
          usuarioId: this.userId,
          titulo: this.nuevaNota.titulo,
          categoria: this.nuevaNota.categoria,
          contenido: this.nuevaNota.contenido,
          fecha: this.nuevaNota.fecha || new Date().toISOString(),
        });
        console.log("Nota guardada con éxito");
        this.mostrarNuevaNota = false;
        this.nuevaNota = { titulo: "", categoria: "", contenido: "", fecha: "" };
        this.cargarNotas(); // Cargar notas
      } catch (error) {
        console.error("Error al guardar la nota:", error);
      }
    },
    async cargarNotas() {
      if (!this.userId) {
        console.error("Usuario no autenticado");
        return;
      }
      try {
        const notasQuery = query(collection(db, "notas"), where("usuarioId", "==", this.userId));
        const querySnapshot = await getDocs(notasQuery);
        this.apuntes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error("Error al cargar las notas:", error);
      }
    },

    // Método para cerrar sesión
    cerrarSesion() {
      const auth = getAuth();
      signOut(auth).then(() => {
        // Redirigir al usuario a la vista Home
        this.$router.push('/');
      }).catch((error) => {
        console.error('Error al cerrar sesión:', error);
      });
    },

    // Metodo de Usuarios

    abrirModalEditarPerfil() {
      this.showEditProfile = true;
    },
    cerrarModalEdicionPerfil() {
      this.showEditProfile = false;
      // Realiza acciones adicionales al cerrar el modal si es necesario
    },

    async guardarCambiosPerfil() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        // Aquí puedes agregar la lógica para actualizar la información del perfil, como nombre, apellido, etc.
        // Puedes utilizar la función updateProfile de Firebase Auth para actualizar la información del perfil.

        await updateProfile(user, {
          displayName: this.user.nombre, // Ajusta según la estructura de tu objeto de usuario
          // Otros campos del perfil
        });

        console.log('Perfil actualizado con éxito');
        this.cerrarModalEdicionPerfil(); // Cierra el modal después de guardar los cambios
      } catch (error) {
        console.error('Error al actualizar el perfil:', error);
        // Aquí puedes manejar el error, por ejemplo, mostrar un mensaje al usuario
      }
    },

    // Otros Métodos
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectItem(item) {
      this.selectedItem = item;
      this.showDropdown = false;
    }
  },
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.userId = user.uid;
        this.userEmail = user.email; // Almacena el correo electrónico del usuario
        this.cargarNotas(); // Cargar notas al iniciar
      } else {
        this.userId = null;
        this.userEmail = null;
      }
    });
    this.loadCategories(); // Cargar categorías al iniciar
  }
};
</script>

<style>
.page-container {
  display: flex;
  height: 100%;
}

.column-2, .column-10 {
  padding: 10px;
}

.column-2 {
  flex: 2;
  background-color: #0E2C40;
  color: white;
}

.column-2 h5 {
  margin-bottom: 0.5rem;
}

.column-2 .email {
  margin-bottom: 0.5rem;
}

.column-2 .btn-edit-profile {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 2em;
  display: block;
  margin: 1em 0;
}

.column-2 .btn-logout {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.9em;
  display: block;
  margin-bottom: 1rem;
}

.column-2 .btn-logout:hover {
  background-color: #d32f2f;
}

.column-2 hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.column-2 button {
  width: 100%;
  text-align: left;
  color: white;
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
}

.column-2 button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.column-2 button i {
  margin-right: 0.5rem;
}

.column-10 {
  flex: 10;
  background-color: #f8f9fa;
}

.card-nueva-nota, .categoria {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 2rem;
  margin-top: 1rem;
}

.categoria h2, .card-nueva-nota h3 {
  margin-bottom: 1.5rem;
}

.form-group, .form-group-c, .form-group-ca {
  margin-bottom: 1rem;
}

.form-group label, .form-group-c label, .form-group-ca label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input, 
.form-group select, 
.form-group-ca input,
.categoria ul {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.categoria ul {
  list-style-type: none;
  padding: 0;
}

.categoria ul li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

.categoria ul li:last-child {
  border-bottom: none;
}

.categoria button, .card-nueva-nota button {
  margin-left: 1rem;
  padding: 0.25rem 0.5rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.categoria button:hover, .card-nueva-nota button:hover {
  background-color: #3e8e41;
}

.apuntes-recientes {
  list-style: none;
  padding: 0;
}

.apuntes-recientes li {
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
}

.apuntes-recientes li:hover {
  color: #ccc;
}

.apunte-detallado {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-top: 1rem;
}

.apunte-detallado h3 {
  margin-bottom: 1rem;
}

.apunte-detallado p {
  margin-bottom: 1rem;
}

.apunte-detallado .footer {
  display: flex;
  justify-content: space-between;
}

.apunte-detallado .categoria {
  font-style: italic;
}

.apunte-detallado .fecha {
  font-weight: bold;
}

/* Estilos para la interfaz de edición de perfil */
.edit-profile-container {
  display: none; /* Esto se establecerá en 'block' mediante Vue.js cuando se deba mostrar */
  background-color: #0E2C40;
  color: white;
  padding: 20px;
  border-radius: 10px;
  position: absolute; /* O podrías usar otro método de posicionamiento dependiendo de tu layout */
  width: 100%; /* O el ancho que desees */
  top: 100px; /* Ajustar según sea necesario */
  left: 0;
  z-index: 10; /* Asegurar que se sobreponga a otros elementos */
}

.edit-profile-container .profile-picture {
  margin-right: 20px;
}

.edit-profile-container .profile-icon {
  font-size: 4em; /* Tamaño del ícono */
}

.edit-profile-container .profile-fields {
  display: flex;
  flex-direction: column;
}

.edit-profile-container .field-container {
  margin-bottom: 10px;
}

.edit-profile-container .field-container label {
  display: block;
  margin-bottom: 5px;
}

.edit-profile-container .field-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.apuntes-recientes li button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding-left: 12px;
  cursor: pointer;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 8px;
  width: 40px;
}

.apuntes-recientes li button:hover {
  background-color: #0056b3;
}

</style>
