// router/index.js
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Registro from '@/views/Registro.vue';
import Login from '@/views/Login.vue';
import Inicio from '@/views/web/Inicio.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', component: Home, meta: { showNavbar: true } },
  { path: '/registro', component: Registro, meta: { showNavbar: true } },
  { path: '/login', component: Login, meta: { showNavbar: true } },
  { path: '/inicio', component: Inicio, meta: { showNavbar: false } }, // Aquí no se mostrará la barra de navegación
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
