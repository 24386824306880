import Vue from 'vue'
import Vuex from 'vuex'
import Usuarios from './modules/Usuarios'
import Ayudas from './modules/Ayudas'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Usuarios,
    Ayudas,
  }
})
