<template lang="pug">
#app
    #nav(v-if="$route.meta.showNavbar")
      Navigation()
      
    router-view
    Footer
    Snackbar
</template>
  
<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer"
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  components:{
    Navigation,
    Footer
  },
  onCreated: () => {

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("hay sesion")
        const uid = user.uid;
        
      } else {
        console.log("no hay sesion")
      
      }
    });
  },
}
</script>


