

<template>
  <div v-if="mostrarModal">
    <div class="modal-content">
      <span class="close" @click="cerrarModal">×</span>
      <h2>Edición de Perfil</h2>
      <div class="profile-fields">
        <!-- Campos para editar el perfil -->
        <label for="nombre">Nombre</label>
        <input type="text" v-model="user.nombre">
        <label for="apellido">Apellido</label>
        <input type="text" v-model="user.apellido">
        <label for="correo">Correo Electrónico</label>
        <input type="email" v-model="user.correo">
        <label for="fotoPerfil">Foto de Perfil</label>
        <input type="file" accept="image/*" @change="cargarFotoPerfil">
      </div>
      <!-- Botón para guardar los cambios -->
      <button type="button" @click="guardarCambiosPerfil">Guardar Cambios</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mostrarModal: Boolean,
    user: Object,
    cerrarModal: Function,
    cargarFotoPerfil: Function,
    guardarCambiosPerfil: Function,
  },
  data() {
    return {
      // Puedes agregar datos locales específicos del modal si es necesario
    };
  },
  methods: {
    // Métodos específicos del modal, si es necesario

    cerrarModal() {
      // Puedes realizar acciones adicionales al cerrar el modal si es necesario
      this.cerrarModal();
    },
  },
};

</script>


<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #0E2C40;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #fff;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>