
<template>
  <div class="modal">
    <div class="modal-content">
      <h2>Editar Apunte</h2>
      <label for="titulo">Título</label>
      <input type="text" v-model="apunteEditado.titulo">
      <label for="contenido">Contenido</label>
      <input type="text" v-model="apunteEditado.contenido">
      <label for="fecha">Fecha</label>
      <input type="date" v-model="apunteEditado.fecha">
      <label for="categoria">Categoria</label>
      <input type="text" v-model="apunteEditado.categoria">
      <button @click="guardarEdicion">Guardar</button>
      <button @click="cerrarModal">Cancelar</button>
    </div>
  </div>
</template>


<script>

export default {
  props: ['apunte'],
  data() {
    return {
      apunteEditado: { ...this.apunte } // Clonar el apunte para no modificar el original directamente
    };
  },
  methods: {
    guardarEdicion() {
      this.$emit('guardar', this.apunteEditado);
    },
    cerrarModal() {
      this.$emit('cerrar');
    }
  }
};

</script>

<style scoped>

/* Estilos para el modal, puedes personalizar según tus necesidades */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

</style>