<template lang="pug">
v-col.pa-0(style="background: #0E2C40;" )
  //-Pc
  .d-none.d-md-block
    .navbar(style="height: 50px")
      .container
            router-link(to="/")
              img.logo(src="../../public/img/icons/Group 1.png" height="50" contain )
            router-link(to="/registro")
              a(href="#", style="color: #fff; margin-left:  50em; text-decoration: none;  font-weight: bold") Registrate
            router-link(to="/Login")
              a( style="color: #fff; margin-right:  1em; text-decoration: none; font-weight: bold") Iniciar sesión
  
  //- movil
  .d-md-none
    .navbar
      router-link(to="/")
        img.logo(src="../../public/img/icons/Group 1.png" height="50" contain style="margin-left: 1em" )
      v-spacer
      v-btn(@click='drawer = !drawer', style='background: transparent; ')
        i.fa.fa-bars(style='color: white; font-size: 30px;')
    v-navigation-drawer( temporary v-model="drawer" app color="#174A43")
      v-list
        v-list-item.justify-center
          router-link(to="/")
            img.logo(src="../../public/img/icons/Group 1.png" height="50" contain style="margin-left: 1em; margin-top: 2em" )

        v-container(style="margin-left: 1.5em; font-size: 18px")
          v-row.justify-center.align-center
            router-link(to="/login").pt-3
              a(href="#", style="color: #fff; text-decoration: none") Iniciar sesión
            br
            router-link(to="/registro").pt-4
              a(href="#", style="color: #fff; text-decoration: none") Registrarse
            
            
</template>
<script>
export default {
  data: ()=>({
    drawer:false,
  }),
}
</script>
<style scoped>

</style>