<template lang="pug">
#snackbar( :class='{show : Ayudas.mostrar_snackbar}') {{Ayudas.mensaje_de_snackbar}}
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      Ayudas: ({Ayudas})=>Ayudas,
    })
  },
};
</script>


<style lang="sass" scoped>
#snackbar
  width: 550px
  background-color: #0d6efd
  color: #fff
  text-align: center
  padding: 10px 30px
  position: fixed
  left: 0
  right: 0
  margin: 0 auto
  bottom: 30px
  border-radius: 4px
  visibility: hidden
  bottom: 0
  opacity: 0
  transition: .3s
  &.show
    visibility: visible
    bottom: 30px
    opacity: 1  
  z-index: 10000
@media only screen and (max-width: 980px) and (min-width: 821px) 

/* Para 800px */  
@media only screen and (max-width: 820px) and (min-width: 621px)  
  
/* Para 600px */  
@media only screen and (max-width: 620px) and (min-width: 501px)
  #snackbar
    width: 250px
/* Para 480px */  
@media only screen and (max-width: 500px) and (min-width: 341px)
  #snackbar
    width: 250px
/* Para 320px */  
@media only screen and (max-width: 340px) and (min-width: 5px)
  #snackbar
    width: 250px
</style>
