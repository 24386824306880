import Vue from 'vue'
import Vuex from 'vuex'
import { getAuth, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword} from "firebase/auth";
import { addDoc, collection, getDocs, getFirestore, query, where } from 'firebase/firestore'

Vue.use(Vuex) 

export default {
    namespaced: true,
    state: {
        usuario: null,
        esAdministrador: false,
        esCliente: false,
    },
    mutations: {
        SET_USUARIO(state, usuario) {
            state.usuario = usuario
        }
    },
    actions: {
        registrarUsuario: ({commit, dispatch}, { email, password, otherUserData }) => new Promise((resolve, reject)=>{
            dispatch('verificarCredenciales', email).then(estaVacio=>{
                if(estaVacio === true) {
                    const auth = getAuth()
                    createUserWithEmailAndPassword( auth, email, password).then(({user})=>{
                        const {uid} = user
                        const usuario = {email, uid, ...otherUserData}
                        const firestore = getFirestore()
                        const coleccion = collection(firestore, 'Usuarios')
                        addDoc(coleccion, {...usuario, creadoEl: new Date()}).then(documento=>{
                            commit('SET_USUARIO', {usuario, idUsuario: documento.id})
                            resolve(true)
                        }).catch(error=>{
                            console.log(error, 'Error en guardado')
                        })
                    }).catch(error=>{
                        console.error(error)
                    })            
                } else {
                    resolve(false)
                    console.log('Ya hay una cuenta')
                }
            })
            
        }),
        iniciarSesion: ({dispatch}, { email, password }) =>  new Promise((resolve, reject)=>{
            dispatch('verificarCredenciales', email).then(estaVacio=>{
                if(estaVacio === false) {
                    const auth = getAuth()
                    signInWithEmailAndPassword( auth, email, password).then(()=>{
                        ///Obtener datos en base al email, y mover lo que está más abajo al .then
                        resolve(true)
                        console.log('Sí hay cuenta xd', email)
                    })
                    
                } else {
                    resolve(false)

                    console.log('No hay una cuenta')
                }
            })
        }),
        verificarCredenciales: (context, email)=>new Promise((resolve, reject)=>{
            if(!email) return
            const firestore = getFirestore()
            const coleccion = collection(firestore, 'Usuarios')
            const consulta = query(coleccion, where('email', '==', email))
            getDocs(consulta).then(({empty})=>{
                resolve(empty)
            }).catch(error=>{
                reject(error)
            })
        }),
        async cerrarSesion(context, { email, password, otherUserData }) {
            try {
                const auth = getAuth()
                const authResponse = await signOut( auth )
                context.commit('SET_USUARIO', {})
            } catch ( error ) {
                console.log(error)
            }
        },
    },
    getters: {
        usuario: ({usuario}) => usuario,
    },
}